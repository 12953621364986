function getPerformanceMetrics() {
  return new Promise((resolve) => {
    // 确保页面加载完成
    if (document.readyState === 'complete') {
      collectMetrics()
    } else {
      window.addEventListener('load', collectMetrics)
    }
  
    function collectMetrics() {
      // 使用 setTimeout 确保所有性能条目都已记录
      setTimeout(() => {
        const navigationEntries = performance.getEntriesByType('navigation')
          
        if (navigationEntries.length > 0) {
          const timing = navigationEntries[0]
            
          const metrics = {
            // DNS 查询时间
            dns: timing.domainLookupEnd - timing.domainLookupStart,
              
            // TCP 连接时间
            tcp: timing.connectEnd - timing.connectStart,
              
            // TLS 协商时间
            tls: timing.secureConnectionStart > 0 ? 
              timing.connectEnd - timing.secureConnectionStart : 0,
              
            // TTFB
            ttfb: timing.responseStart - timing.requestStart,
              
            // 下载时间
            download: timing.responseEnd - timing.responseStart,
              
            // DOM 内容加载时间
            domContentLoaded: timing.domContentLoadedEventEnd - timing.fetchStart,

            // 页面总加载时间
            pageLoad: timing.loadEventEnd - timing.loadEventStart,
              
          }
          
         
          resolve( Object.keys(metrics).reduce((pre, key) => {
            if (metrics[key] !== 0) {
              pre.push({
                key,
                value: metrics[key].toFixed(4)
              })
            }

            return pre
          }, []))
        } else {
          resolve({
            error: 'Navigation timing entries not available'
          })
        }
      }, 0)
    }
  })
}

class TestTrackerTime {
  constructor() {
    this.data = []
    this.timer = null
  }

  record(data = []) {
    this.data = data.map(item => {
      return {
        key: item.key_path,
        value: item.values.num
      }
    })
    this.displayData()
  }

  /**
   * @description: 展示内容在页面中
   */
  displayData() {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      const arr = this.data
      getPerformanceMetrics().then(metrics => {

        const divEl = document.createElement('div')
        divEl.style.position = 'fixed'
        divEl.style.top = '10%'
        divEl.style.left = '10%'
        divEl.style.backgroundColor = 'rgba(0,0,0,0.5)'
        divEl.style.color = '#fff'
        divEl.style.padding = '10px'
        divEl.style.zIndex = '9999'
        divEl.style.transform = `translate3d(0,0,9999px)`
        divEl.style.borderRadius = '5px'
  
        if (window.gbRawData?.___serverLoggerInfo) {
          arr.push({
            key: 'traceId',
            value: window.gbRawData?.___serverLoggerInfo
          })
        }
        const innerHTML = arr.concat(metrics)
          .map(item => {
            return `<div style="padding:2px 0;font-size: 12px;">${item.key}: ${item.value}</div>`
          })
          .join('')
  
        divEl.innerHTML = innerHTML
        document.body.appendChild(divEl)
        setTimeout(() => {
          document.body.removeChild(divEl)
        }, 4000)

      }).catch(error => {
        console.error('Error getting performance metrics:', error)
      })
     
  
    }, 1000)
  }
}

const testTrackerTime = new TestTrackerTime()

export { testTrackerTime }
