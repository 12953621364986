import { windowCacheDeleteCb } from '@shein/common-function'
import { onWindowLoad } from 'public/src/pages/common/utils/index.js'
import { abtservice } from 'public/src/services/abt'

if ('serviceWorker' in navigator && typeof fetch !== 'undefined') {
  onWindowLoad(() => {
    requestUserIdle(async () => {
      const { langPath } = gbCommonInfo
      // 通过abt控制当前sheinPush是否生效
      // eslint-disable-next-line @shein-aidc/abt/abt
      const { webpushsubscribe } = await abtservice.getUserAbtResult({
        newPosKeys: 'webpushsubscribe'
      })
      const isOpenSheinPush = webpushsubscribe.param?.webpush_subscribe === 'on'
      let swFile
      if(isOpenSheinPush){
        swFile = '/firebase-messaging-sw.js'
      }else{
        swFile = langPath + '/sw-runtime-cache.js'
      }
      try {
        await navigator.serviceWorker.register(swFile)
        window.dispatchEvent(new Event('SW_Loaded'))
        window.SW_Loaded = true
      } catch (error) {
        console.error('ServiceWorker 注册失败。', error)
      }
    })
  })
}

// 页面访问时清除sw缓存
(() => {
    const swCacheClearConfig = {
      '/user/auth/login': ['pcMeHtml']
    }

    Object.keys(swCacheClearConfig).forEach((key) => {
      if(location.pathname.includes(key)) {
        windowCacheDeleteCb({ key: swCacheClearConfig[key] })
      }
    })
})()
