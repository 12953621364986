
import { getPreFetchProductList, triggerPreFetchProductList } from '../productList'
import { getPreFetchStoreProductList, triggerPreFetchStoreProductList, isStorePage } from '../store'
import { triggerPreFetchDetailRealTimeData } from '../detail'

export const preFetchGoodsPromise = () => {
  if (typeof window === 'undefined') return Promise.resolve()
  return isStorePage() ? getPreFetchStoreProductList() : getPreFetchProductList()
}

export const triggerPreFetchProductListPromise = () => {
  triggerPreFetchDetailRealTimeData(),
  triggerPreFetchStoreProductList()
  triggerPreFetchProductList()
}
