import { asyncLoadFile, onWindowLoad } from 'public/src/pages/common/utils/index.js'
/**
 * PC 前置逻辑过慢, 埋点丢失严重, 临时前置
 */
import buildManifest from './analytics-manifest.js'
const { PUBLIC_CDN, TPM_BLACKLIST, SiteUID, SA_SDK_CONFIG = {} } = gbCommonInfo
// const SA_SDK_CONFIG = {
//   loading: true, // 是否引入新SDK
//   sampling: true, // 是否开启采样
// }
if (!TPM_BLACKLIST['SheinAnalytics']?.[SiteUID]) {
  // 旧SDK
  // 如果没有引入新SDK || 引入新SDK且开启采样（双通道）
  if (!SA_SDK_CONFIG.loading || SA_SDK_CONFIG.loading && SA_SDK_CONFIG.sampling) {
    asyncLoadFile({
      type: 'script',
      src: `${PUBLIC_CDN}/she_dist/js/analysis/${buildManifest['sdk_analysis']}` // NOTICE: 确保静态资源存在
    })
  }
  // 新SDK
  if (SA_SDK_CONFIG.loading) {
    // 需要采样（双通道上报），否则全量替换旧 SDK
    // 双通道需要给一个新的变量，这个变量将会在旧 SDK 中使用
    if (SA_SDK_CONFIG.sampling) {
      window.web_sa_fix = function() {
        if (!window.web_sa_fix.waitingCommands) window.web_sa_fix.waitingCommands = []
        window.web_sa_fix.waitingCommands.push(arguments)
      }
    }
    asyncLoadFile({
      type: 'script',
      // 资源来源：basis-lib 仓库下的 sa/bundle/web-sa.umd
      src: `${PUBLIC_CDN}/she_dist/libs/web-sa.1055.js` // NOTICE: 确保静态资源存在
    })
  }
}

/**
 * End
 */

import './ga'
import './sa'

import DeclarativeAnalysis from './declarative'


onWindowLoad(function () {
  const declarativeAnalysis = new DeclarativeAnalysis()
  declarativeAnalysis.init()
})
